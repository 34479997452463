var exports = {};
exports = {
  A: {
    A: {
      "2": "J 6B",
      "2340": "D E F A B"
    },
    B: {
      "2": "C K L G M N O",
      "1025": "P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H"
    },
    C: {
      "1": "e f g h i j k l m n o p q c H uB vB",
      "2": "7B qB 8B",
      "513": "aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b",
      "545": "0 1 2 3 4 5 6 7 8 9 I r J D E F A B C K L G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB 9B"
    },
    D: {
      "2": "0 1 2 3 4 5 6 7 8 9 I r J D E F A B C K L G M N O s t u v w x y z AB BB CB DB",
      "1025": "EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB AC BC"
    },
    E: {
      "1": "A B C K L G xB nB oB yB HC IC zB 0B 1B 2B pB 3B JC KC",
      "2": "I r CC wB DC",
      "164": "J",
      "4644": "D E F EC FC GC"
    },
    F: {
      "2": "0 F B G M N O s t u v w x y z LC MC NC OC nB 4B",
      "545": "C PC oB",
      "1025": "1 2 3 4 5 6 7 8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b"
    },
    G: {
      "1": "XC YC ZC aC bC cC dC eC fC gC hC iC jC zB 0B 1B 2B pB 3B",
      "2": "wB QC 5B",
      "4260": "RC SC",
      "4644": "E TC UC VC WC"
    },
    H: {
      "2": "kC"
    },
    I: {
      "2": "qB I lC mC nC oC 5B pC qC",
      "1025": "H"
    },
    J: {
      "2": "D",
      "4260": "A"
    },
    K: {
      "2": "A B nB 4B",
      "545": "C oB",
      "1025": "d"
    },
    L: {
      "1025": "H"
    },
    M: {
      "1": "c"
    },
    N: {
      "2340": "A B"
    },
    O: {
      "1025": "rC"
    },
    P: {
      "1025": "I sC tC uC vC wC xB xC yC zC 0C 1C pB 2C 3C 4C"
    },
    Q: {
      "1025": "yB"
    },
    R: {
      "1025": "5C"
    },
    S: {
      "4097": "6C"
    }
  },
  B: 4,
  C: "Crisp edges/pixelated images"
};
export default exports;